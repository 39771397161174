globale {
  display: none; }

.globale-selector.country-selector {
  cursor: pointer; }

@media (min-width: 992px) {
  .globale-selector.menu-item {
    display: none; } }

@media (max-width: 768.98px) {
  .globale-selector.menu-item {
    border-top: 1px solid #eee; } }

@media (min-width: 769px) {
  .globale-selector.menu-item .btn {
    color: #fff;
    padding: .8rem; } }

@media (max-width: 768.98px) {
  .globale-selector.menu-item .btn {
    color: #444; } }
